import BuildIcon from '@mui/icons-material/Build';
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HomeIcon from '@mui/icons-material/Home';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonPinIcon from "@mui/icons-material/PersonPin";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { logout } from "../../redux/features/auth/authSlice";
import LogoStemm from './logoStemm.png';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: '#1976d2',
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  backgroundColor: '#1976d2',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const tokenRoles = localStorage.getItem("roles");

const buttonName = ["Accueil", "Présences", "Evènements"];
const linkTo = ["/", "/Presences", "/Event"];
const icons = [<HomeIcon />, <PersonPinIcon />, <LocalActivityIcon />];

const userBlockButtonName = ["Mon compte", "Changer de site"];
const userBlockLinkTo = ['/Settings', '/SiteSelect'];
const userBlockIcons = [<ManageAccountsIcon />, <LocationOnIcon />];

if (tokenRoles?.includes("ADMIN") ||
  tokenRoles?.includes("EDUCATEUR") ||
  tokenRoles?.includes("ENCADRE")) {
  buttonName.push("Clients");
  linkTo.push('/Clients');
  icons.push(<SupervisorAccountIcon />);
  
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: '#1976d2',
  ...({
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
}));

function NavBarSide(props) {
  const dispatch = useDispatch();
  const { currentUser, site, active, setActive } = props;


  // const [openSettings, setOpenSettings] = useState(false);
  // const [openReporting, setOpenReporting] = useState(false);
  // const [openManagement, setOpenManagement] = useState(false);
  const [openUserBlock, setOpenUserBlock] = useState(false);

  // const handleClickSettings = () => {
  //   setOpenSettings(!openSettings);
  // };

  // const handleClickReporting = () => {
  //   setOpenReporting(!openReporting);
  // }

  // const handleClickManagement = () => {
  //   setOpenManagement(!openManagement);
  // }

  const handleClickUserBlock = () => {
    if (active === buttonName.length + 2) {
      setOpenUserBlock(true);
    } else {
      setOpenUserBlock(false);
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={true}>
        <DrawerHeader
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            height: "70px",
            marginTop: 1,
          }}
        >
          <Link to="/">
            <img
              src={LogoStemm}
              alt="Stemm"
              style={{ width: "60%", filter: "sepia(10%)" }}
            ></img>
          </Link>
        </DrawerHeader>
        <Link
          to="/SiteSelect"
          onClick={() => {
            setOpenUserBlock(true);
            setActive(1 + buttonName.length + 3);
          }}
        >
          <h4 style={{ color: "#fff", marginTop: 4, marginLeft: 12 }}>
            {site?.description}
          </h4>
        </Link>

        <Divider
          sx={{
            margin: "1% 0% 5% 0%",
            border: "1px solid #135ca4",
            width: "77%",
          }}
        />

        <List sx={{ textDecoration: "none" }}>
          {buttonName.map((text, index) => (
            <Link key={index} to={linkTo[index]} underline="none">
              <ListItem key={text} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: "initial",
                    px: 2.5,
                  }}
                  onClick={() => {
                    setActive(index);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: active === index ? "#bc270d" : "white",
                      minWidth: 0,
                      mr: 3,
                      justifyContent: "center",
                    }}
                  >
                    {icons[index]}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    sx={{
                      opacity: 1,
                      color: active === index ? "#bc270d" : "white",
                      textDecoration: "none",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          ))}

          <Divider
            sx={{ margin: "5% 0%", border: "1px solid #135ca4", width: "77%" }}
          />

          {/* {tokenRoles?.includes("ADMIN", "EDUCATEUR", "ENCADRE") && ( */}
            <>
              <Link to="/gestionSelect">
                <ListItem
                  key={"Gestion Roger"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: "initial",
                      px: 2.5,
                    }}
                    onClick={() => {
                      setActive(buttonName.length + 1);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          active === buttonName.length + 1
                            ? "#bc270d"
                            : "white",
                        minWidth: 0,
                        mr: 3,
                        justifyContent: "center",
                      }}
                    >
                      <BuildIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Gestion Roger"}
                      sx={{
                        opacity: 1,
                        color:
                          active === buttonName.length + 1
                            ? "#bc270d"
                            : "white",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            </>
          {/* )} */}

          {tokenRoles?.includes("ADMIN") && (
            <>
              <Link to="/settingsSelect">
                <ListItem
                  key={"Paramètres"}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: "initial",
                      px: 2.5,
                    }}
                    onClick={() => {
                      setActive(buttonName.length);
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          active === buttonName.length ? "#bc270d" : "white",
                        minWidth: 0,
                        mr: 3,
                        justifyContent: "center",
                      }}
                    >
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Paramètres"}
                      sx={{
                        opacity: 1,
                        color:
                          active === buttonName.length ? "#bc270d" : "white",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Link>
            </>
          )}

          <Divider
            sx={{ margin: "5% 0%", border: "1px solid #135ca4", width: "77%" }}
          />

          <ListItemButton
            sx={{
              color: active === buttonName.length + 2 ? "#bc270d" : "white",
              minHeight: 48,
              justifyContent: "initial",
              px: 2.5,
            }}
            onClick={() => {
              setActive(buttonName.length + 2);
              handleClickUserBlock();
            }}
          >
            <ListItemText
              primary={
                currentUser?.username == null
                  ? "Utilisateurs"
                  : currentUser?.username
              }
              sx={{
                opacity: 1,
                color: active === buttonName.length + 2 ? "#bc270d" : "white",
                textDecoration: "none",
              }}
            />
            {active >= buttonName.length + 2 ? <ExpandMore /> : <ExpandLess />}
          </ListItemButton>
          <Collapse
            in={active >= buttonName.length + 2}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {userBlockButtonName.map((text, index) => (
                <Link key={index} to={userBlockLinkTo[index]} underline="none">
                  <ListItem
                    key={"settings" + text}
                    disablePadding
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: "initial",
                        px: 2.5,
                      }}
                      onClick={() => {
                        setActive(index + buttonName.length + 3);
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color:
                            active === index + buttonName.length + 3
                              ? "#bc270d"
                              : "white",
                          minWidth: 0,
                          mr: 3,
                          justifyContent: "center",
                        }}
                      >
                        {userBlockIcons[index]}
                      </ListItemIcon>
                      <ListItemText
                        primary={text}
                        sx={{
                          opacity: 1,
                          color:
                            active === index + buttonName.length + 3
                              ? "#bc270d"
                              : "white",
                          textDecoration: "none",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
              <ListItem key={"logout"} disablePadding sx={{ display: "block" }}>
                <Link to="/Login">
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: "initial",
                      px: 2.5,
                    }}
                    onClick={() => {
                      dispatch(logout());
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: "#000",
                        minWidth: 0,
                        mr: 3,
                        justifyContent: "center",
                      }}
                    >
                      <PowerSettingsNewIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Déconnexion"}
                      sx={{ opacity: 1, color: "#000" }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            </List>
          </Collapse>
        </List>
      </Drawer>
    </Box>
  );
}

export default NavBarSide;
