import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/features/auth/authSlice";
import { SiteService } from "../service/SiteService";

import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CheckroomIcon from "@mui/icons-material/Checkroom";
import CloseIcon from "@mui/icons-material/Close";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import DoneIcon from "@mui/icons-material/Done";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import ShowerIcon from "@mui/icons-material/Shower";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from '@mui/material/IconButton';
import { Link } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";
import ButtonManager from "../../../shared/components/Button/ButtonManager";

// import LogoStemm from './../../logoStemm.png';
import "./SiteListing.css";


const columns = [
  {
    field: "id",
    headerName: "Id",
    hideable: true,
  },
  {
    field: "description",
    headerName: "Description",
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    flex: 1,
    width: 140,
  },
  {
    field: "address",
    headerName: "Addresse",
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    flex: 1,
    width: 140,
  },
  {
    field: "phone",
    headerName: "Tel.",
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    flex: 1,
    width: 80,
  },
  {
    field: "fax",
    headerName: "Fax",
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    flex: 1,
    width: 80,
  },
  {
    field: "website",
    headerName: "Site Web",
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    flex: 1,
    width: 140,
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    flex: 1,
    width: 150,
  },
  {
    field: "welcometitle",
    headerName: "Titre de bienvenue",
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    flex: 1,
    width: 150,
  },
  {
    field: "shower",
    headerName: <ShowerIcon />,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    flex: 1,
    width: 80,
  },
  {
    field: "lockerroom",
    headerName: <CheckroomIcon />,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    flex: 1,
    width: 80,
  },
  {
    field: "laundry",
    headerName: <LocalLaundryServiceIcon />,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    flex: 1,
    width: 80,
  },
  {
    field: "restaurant",
    headerName: <RestaurantIcon />,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (data) =>
      data.value ? <DoneIcon color="success" /> : <CloseIcon color="warning" />,
    width: 80,
  },
  {
    field: "hairdresser",
    headerName: <ContentCutIcon />,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (data) =>
      data.value ? <DoneIcon color="success" /> : <CloseIcon color="warning" />,
    width: 80,
  },
  {
    field: "doctor",
    headerName: <LocalHospitalIcon />,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (data) =>
      data.value ? <DoneIcon color="success" /> : <CloseIcon color="warning" />,
    width: 80,
  },
  {
    field: "sleepingbag",
    headerName: <AirlineSeatIndividualSuiteIcon />,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (data) =>
      data.value ? <DoneIcon color="success" /> : <CloseIcon color="warning" />,
    width: 80,
  },
  {
    field: "deleted",
    headerName: <HighlightOffIcon />,
    align: "center",
    headerClassName: "eventListingTheme",
    flex: 1,
    headerClassName: "researchColmun",
    headerAlign: "center",
    align: "center",
    renderCell: (data) =>
      data.value ? <DoneIcon color="success" /> : <CloseIcon color="warning" />,
    width: 80,
  },
];

const forms = [
  { name: "description", label: "Description", type: "string" },
  { name: "address", label: "Address", type: "string" },
  { name: "phone", label: "Téléphone", type: "string" },
  { name: "fax", label: "Fax", type: "string" },
  { name: "website", label: "Site Web", type: "string" },
  { name: "email", label: "Email", type: "string" },
  { name: "welcometitle", label: "Titre de bienvenue", type: "string" },
  { name: "shower", label: "Douche", type: "number" },
  { name: "lockerroom", label: "Vestiaire", type: "number" },
  { name: "laundry", label: "Buanderie", type: "number" },
  { name: "restaurant", label: "Restaurant", type: "checkbox" },
  { name: "hairdresser", label: "Coiffeur", type: "checkbox" },
  { name: "doctor", label: "Docteur", type: "checkbox" },
  { name: "sleepingbag", label: "Sac de couchage", type: "checkbox" },
  { name: "deleted", label: "Supprimer", type: "checkbox" },
];

const SiteListing = () => {
  const dispatch = useDispatch();

  const currentPage = 0;
  const pageSize = 50;
  const [rows, setRows] = useState([]);
  const [select, setSelect] = useState(null);

  const loadData = () => {
    SiteService.getSites(currentPage, pageSize)
      .then((response) => {
        const data = response.data.content.sort((site1, site2) =>
          site1.description.localeCompare(site2.description)
        );
        setRows(
          data.map((row) => {
            const { siteId, ...rest } = row;
            return { id: siteId, ...rest };
          })
        );
        let selected = data.filter((site) => site.siteId === select.siteId);
        setSelect(selected.length ? selected[0] : {});
      })
      .catch((err) => {
        if (
          err?.statusCode === 403 ||
          err?.statusCode === 401 ||
          err?.response?.status === 403 ||
          err?.response?.status === 401
        ) {
          dispatch(logout());
        }
      });
  };

  useEffect(() => loadData(), []);

  if (rows) {
    return (
      <>
        <div style={{ position: "absolute", top: 23, left: 270 }}>
          <Link to="/SettingsSelect">
            <IconButton color="primary">
              <ArrowBackIosNewIcon />
            </IconButton>
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "center",
            padding: "2%",
          }}
        >
          <h1
            style={{
              fontFamily: "backToSchool",
              fontSize: "65px",
              color: "#bb0000",
            }}
          >
            Sites
          </h1>
          <div className="event-container display-relative">
            <ButtonManager
              isContained={true}
              title={"sites"}
              id={select?.siteId}
              selected={select}
              align={"center"}
              forms={forms}
              createFunction={SiteService.createSite}
              updateFunction={SiteService.updateSite}
              deleteFunction={SiteService.deleteSite}
              requiredFields={["description"]}
              requiredFieldLabel={["Description"]}
              refresh={loadData}
            />

            <div className="EventDataGrid">
              {/* <div style={{ height: 650, width: '100%' }}> */}
              <DataGrid
                sx={{ backgroundColor: "#fff" }}
                rows={rows}
                columns={columns}
                showCellRightBorder
                autoPageSize={true}
                rowsPerPageOptions={[pageSize]}
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = rows.filter((row) =>
                    selectedIDs.has(row.id.toString())
                  );
                  if (selectedRowData.length) {
                    const { id, ...rest } = selectedRowData[0];
                    setSelect({ siteId: id, ...rest });
                  } else {
                    setSelect(null);
                  }
                }}
                columnVisibilityModel={{
                  id: false,
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Box textAlign="center" mt={40}>
          <LoadingButton loading={true} variant="outlined" disabled>
            Fetch data
          </LoadingButton>
        </Box>
      </>
    );
  }
};

export default SiteListing;
