import React, { useEffect, useState } from "react";
import { SiteService } from "../../../site/service/SiteService";
import {
    Box,
    FormControl,
    FormControlLabel,
    InputLabel,
    Checkbox,
    // Select,
    MenuItem
} from '@mui/material';
import { useDispatch } from "react-redux";
import { logout } from "../../../../redux/features/auth/authSlice";
import Select from 'react-select'

export const SiteSelector = (props) => {

    const { handleChange, type, inputs, setInputs } = props;

    const dispatch = useDispatch();

    const [sites, setSites] = useState(null);
    const [option, setOption] = useState(null);

    useEffect(() => {
        SiteService.getSites(0, -1)
            .then(response => {
                let sites = response.data.content;
                handleChange({ target: { name: "sites", value: JSON.stringify(sites) } });
                setSites((sites.length > 0) ?
                    sites
                        .filter(element => element?.description !== '' && element?.deleted !== true)
                        .sort((site1, site2) => site1?.description?.localeCompare(site2?.description))
                    : []
                );
                let array = [];
                for (let i = 0; i < sites.length; i++) {
                    array.push({
                        value:sites[i].description,
                        label:sites[i].description,
                        description:sites[i].description,
                        siteId:sites[i].siteId,
                        restaurant: sites[i].restaurant,
                        adress: sites[i].adress,
                        deleted: sites[i].deleted,
                        doctor: sites[i].doctor,
                        shower:sites[i].shower,
                        email: sites[i].email,
                        fax: sites[i].fax,
                        hairdresser: sites[i].hairdresser,
                        laundry: sites[i].laundry,
                        lockerroom: sites[i].lockerroom,
                        phone: sites[i].phone,
                        sleepingbag: sites[i].sleepingbag,
                        website: sites[i].website,
                        welcometitle: sites[i].welcometitle,
                    })
                }
                setOption(array);
            })
            .catch(err => {
                console.log(err);
                if (err?.statusCode === 403 || err?.statusCode === 401
                    || err?.response?.status === 403 || err?.response?.status === 401
                ) {
                    dispatch(logout());
                }
            });
    }, []);

    const handleChangeSiteCheckbox = (e) => {
        setInputs((values) => ({ ...values, ["sites"]: e }));
    };

    const handleChangeSite = (e) => {
        setInputs((values) => ({ ...values, ["sites"]: [e] }));
    };

    if (type === "checkbox") {
        return (
            <div style={{ margin:'15px 0px 10px 0px', display:'flex', flexDirection:'row', justifyContent:'center', textAlign:'center', alignItems:'center' }}>
                <span style={{ fontSize:'20px', fontFamily:'overlockBold', width:'30%', wordWrap:'none' }}>Sites:</span>
                <div style={{ width:'100%' }}>
                    <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isMulti
                        options={option}
                        onChange={handleChangeSiteCheckbox}
                        placeholder={"Sélectioner le(s) site(s)"}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ margin:'15px 0px 10px 0px', display:'flex', flexDirection:'row', justifyContent:'center', textAlign:'center', alignItems:'center' }}>
                <span style={{ fontSize:'20px', fontFamily:'overlockBold', width:'30%', wordWrap:'none' }}>Sites:</span>
                <div style={{ width:'100%' }}>
                    <Select
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={option}
                        onChange={handleChangeSite}
                        placeholder={"Sélectioner le(s) site(s)"}
                    />
                </div>
            </div>
        );
    }
}
