import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../../../redux/features/auth/authSlice";
import {
  getISODate,
  localeISODateTime,
} from "../../../../../shared/utils/localeISODateTime";
import { CustomDatePicker } from "../../../../datepicker/CustomDatePicker";
import { ExportDataGridToExcel } from "../../../../export/ExportDataGridToExcel";
import { SiteService } from "../../../../site/service/SiteService";
import { TransactionService } from "../../../service/SubService/TransactionService";

const columns = [
  {
    field: "id",
    headerName: "Id",
    hideable: true,
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
  },
  {
    field: "date",
    headerName: "Date",
    hideable: false,
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
    renderCell: (data) => moment(data.value).format("DD-MM-YYYY HH:mm"),
  },
  {
    field: "site",
    headerName: "Site",
    hideable: false,
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
    renderCell: (data) => data?.value?.description,
  },
  {
    field: "amount",
    headerName: "Nouveau montant",
    hideable: false,
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
  },
  {
    field: "oldAmount",
    headerName: "Ancien Montant",
    hideable: false,
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
  },
  {
    field: "diff",
    headerName: "Différentiel",
    hideable: false,
    flex: 1,
    headerAlign: "center",
    align: "center",
    headerClassName: "researchColmun",
    renderCell: (data) => {
      let diff =
        parseFloat(data?.row?.amount) - parseFloat(data?.row?.oldAmount);
      let color = diffColor(data.row);
      return (
        <div style={{ color: color.color, fontWeight: "bold" }}>{Number(diff)?.toFixed(2)}</div>
      );
    },
  },
];

const colorScheme = {
  positive: { color: "green" },
  negative: { color: "red" },
  neutral: { color: "orange" },
};

const diffColor = (row) => {
  let diff = parseFloat(row?.amount) - parseFloat(row?.oldAmount);
  let color;
  if (diff > 0) {
    color = colorScheme["positive"];
  } else if (diff < 0) {
    color = colorScheme["negative"];
  } else {
    color = colorScheme["neutral"];
  }
  return color;
};

const ClientTransaction = (props) => {
  const { client } = props;

  const dispatch = useDispatch();
  const todaystr = getISODate(localeISODateTime());

  const theme = useTheme();

  const [site, setSite] = useState(
    useSelector((state) => state?.site.site.siteId)
  );
  const [sites, setSites] = useState([]);
  const [inputs, setInputs] = useState({
    date: todaystr,
  });

  const [transactions, setTransactions] = useState(null);
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 10,
  });

  const [rowCountState, setRowCountState] = useState(null);
  const [defaultPageNo, setDefaultPageNo] = useState(0);
  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const handleDateChange = (date) => setInputs({ date: date });

  const resetInput = () => {
    setInputs({ date: "" });
    setSite("");
  };

  const refresh = (params) => {
    const { pageNo, pageSize } = params || {
      pageNo: defaultPageNo,
      pageSize: defaultPageSize,
      client: client,
      site: site,
    };
    setLoading(true);
    return TransactionService.getTransactionsByClientSite(
      pageNo,
      pageSize,
      getISODate(localeISODateTime(inputs.date)),
      client?.clientId,
      site
    )
      .then((response) => {
        let data = response.data.content;
        setTransactions(
          data.map((row) => {
            const { prepaidTransactionId, ...rest } = row;
            return { id: prepaidTransactionId, ...rest };
          })
        );
        setRowCountState(response.data.totalElements);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.statusCode === 403 || err?.statusCode === 401) {
          dispatch(logout());
        }
      });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSite(value);
  };
  useEffect(() => {
    if (client != null) {
      SiteService.getSites(0, -1)
        .then((response) => {
          let data = response.data.content;
          setSites(
            data.length > 0
              ? data
                  .filter(
                    (element) =>
                      element?.description !== "" && element?.deleted !== true
                  )
                  .sort((site1, site2) =>
                    site1?.description?.localeCompare(site2?.description)
                  )
              : []
          );
        })
        .catch((err) => {
          if (
            err?.statusCode === 403 ||
            err?.statusCode === 401 ||
            err?.response?.status === 403 ||
            err?.response?.status === 401
          ) {
            dispatch(logout());
          }
        });
      refresh({
        pageNo: 0,
        pageSize: -1,
        inputs: { ...inputs, date: getISODate(localeISODateTime(inputs.date)) },
      });
    }
  }, [client]);

  useEffect(() => {
    const timeoutId = setTimeout(
      () =>
        refresh({
          pageNo: 0,
          pageSize: -1,
          inputs: {
            ...inputs,
            date: getISODate(localeISODateTime(inputs.date)),
          },
        }),
      500
    );
    return () => clearTimeout(timeoutId);
  }, [inputs, site, client]);

  if (client == null) {
    return <p>Pas de client sélectionné</p>;
  }

  if (client != null && transactions != null) {
      return (
        <div className="event-container display-relative">
          <div className="event-form">
            <div
              className="row d-flex align-items-center justify-content-between"
              style={{ marginTop: "20px" }}
            >
              <div className="col-3">
                <CustomDatePicker
                  valueDate={inputs.date}
                  handleChange={handleDateChange}
                />
              </div>
              <div className="col-3">
                <select name="site" value={site} onChange={handleChange}>
                  <option key={null} value={""}>
                    Choissiez un site
                  </option>
                  {sites.map((site) => (
                    <option key={site?.description} value={site?.siteId}>
                      {site?.description}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3 d-flex">
                <div className="clearResearch">
                  <Tooltip title="Vider la recherche" placement="top">
                    <button onClick={resetInput}>
                      <FilterAltOffIcon />
                    </button>
                  </Tooltip>
                </div>
              </div>
              <div className="col-3 d-flex justify-content-end">
                <ExportDataGridToExcel
                  Transactions
                  headers= {[...columns, {
                    field: "client",
                    headerName: "Client",
                    hideable: false,
                    }
                  ]}
                  data={transactions?.map((row) => {
                    let diff = parseFloat(row?.amount) - parseFloat(row?.oldAmount);
                    return {
                      id: row?.id,
                      date: moment(row?.date).format("DD-MM-YYYY HH:mm"),
                      site: row?.site?.description,
                      amount: row?.amount,
                      oldAmount: row?.oldAmount,
                      diff: diff,
                      client: ((row?.client?.firstnametempo || "") + " " + (row?.client?.lastnametempo || "")).trim(),
                    }
                  })}
                  filename="transactions"
                />
              </div>
            </div>
          </div>
          <div className="EventDataGrid">
            <DataGrid
              rowsPerPageOptions={[rowsState.pageSize]}
              getRowClassName={(params) =>
                `transaction-roger-theme-${diffColor(params.row).color}`
              }
              sx={{ backgroundColor: "#fff" }}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
              columns={columns}
              rows={transactions}
              rowCount={rowCountState}
              pagination
              showCellRightBorder
              {...rowsState}
              paginationMode="server"
              onPageChange={(page) => {
                setDefaultPageNo(page);
                setRowsState((prev) => ({ ...prev, page }));
                refresh({ pageNo: page, pageSize: defaultPageSize });
              }}
              onPageSizeChange={(pageSize) => {
                setDefaultPageSize(pageSize);
                setRowsState((prev) => ({ ...prev, pageSize }));
                refresh({ pageNo: defaultPageNo, pageSize: pageSize });
              }}
            />
          </div>
        </div>
      );
    }
};
export default ClientTransaction;
