import React, { useState, useEffect } from "react";
import ResearchClient from "../../client/pages/ResearchClients";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme, styled, alpha } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CompressIcon from "@mui/icons-material/Compress";
import { logout } from "../../../redux/features/auth/authSlice";
import ClientPartialInformation from "./ClientPartialInformation";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import IconButton from "@mui/material/IconButton";
import { BalanceService } from "../../client/service/SubService/BalanceService";
import { ClientService } from "../../client/service/ClientService";
import { localeISODateTime } from "../../../shared/utils/localeISODateTime";
import "./ClientPartialInformation.css";

function ClientFusion(props) {
  const { setActive } = props;
  useEffect(() => {
    //setActive(3);
  }, []);

  const dispatch = useDispatch();

  const [today, setToday] = useState(localeISODateTime());

  const [loadingMerge, setLoadingMerge] = useState(false);
  const [message, setMessage] = useState("");
  const [clientToRemove, setClientToRemove] = useState(null);
  const [openToRemove, setOpenToRemove] = useState(false);
  const handleCloseToRemove = () => setOpenToRemove(false);

  const [clientToKeep, setClientToKeep] = useState(null);
  const [openToKeep, setOpenToKeep] = useState(false);
  const handleCloseToKeep = () => setOpenToKeep(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  function handleSelectClientToKeep(client) {
    if (client != null) {
      BalanceService.getBalance(client.clientId).then(r => {
        client.balance = r.data.balance;
        setClientToKeep(client);
      })
      handleCloseToKeep();
    }
  }

  function handleSelectClientToRemove(client) {
    if (client != null) {
      BalanceService.getBalance(client.clientId).then(r => {
        client.balance = r.data.balance;
        setClientToRemove(client);
      })
      handleCloseToRemove();
    }
  }

  function removeClient() {
    setLoadingMerge(true);
    if (
      clientToRemove != null &&
      clientToKeep != null &&
      clientToKeep.clientId != clientToRemove.clientId
    ) {
      ClientService.deleteClient(clientToRemove.clientId)
        .then((r) => {
          setClientToRemove(null);
          setMessage("Le client a bien été supprimé");
          setLoadingMerge(false);
        })
        .catch((err) => {
          console.log(err);
          if (
            err?.statusCode === 403 ||
            err?.statusCode === 401 ||
            err?.response?.status === 403 ||
            err?.response?.status === 401
          ) {
            dispatch(logout());
          }
        });
    } else {
      setMessage(
      "Les deux clients doivent être remplis & avoir des ids différents"
    );}
    
    setLoadingMerge(false);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', width: '100%' }}>
      <h1 style={{ fontFamily: 'backToSchool', fontSize: '65px', color: '#bb0000', marginTop: '4%' }}>Fusion</h1>
      <div style={{ position: "absolute", top: 23, left: 270 }}>
        <Link to="/SettingsSelect">
          <IconButton color="primary">
            <ArrowBackIosNewIcon />
          </IconButton>
        </Link>
      </div>
      <div
        className="event-container display-relative"
        style={{ marginTop: "5%" }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }} className='myBox'>
          {/* <Grid container> */}
          <div className='boxItem'>
            <Grid item  sx={{width:'100%'}}>
              <Grid>
                <ClientPartialInformation
                  client={clientToKeep}
                  title={"  À conserver"}
                  suppr={false}
                />
              </Grid>
              <br />
              <Grid>
                <LoadingButton
                  variant="contained"
                  onClick={() => {
                    setOpenToKeep(true);
                    setMessage("");
                  }}
                >
                  <SearchIcon />
                  Rechercher
                </LoadingButton>
              </Grid>
            </Grid>
          </div>
          <div id='a'>
          <button
            onClick={() => {
              removeClient();
            }}
            className="FusionButton"
        >
           <CompressIcon />{"Fusion"}
        </button>
          </div>
          <div id='b' className='boxItem'>
            <Grid item  sx={{width:'100%'}}>
              <Grid>
                <ClientPartialInformation
                  client={clientToRemove}
                  title={"  À supprimer"}
                  suppr={true}
                />
              </Grid>
              <br />
              <Grid>
                <LoadingButton
                  variant="contained"
                  onClick={() => {
                    setOpenToRemove(true);
                    setMessage("");
                  }}
                >
                  <SearchIcon />
                  Rechercher
                </LoadingButton>
              </Grid>
            </Grid>
          </div>
        </div>
        {/* </Grid> */}

        {/* <div style={{ height: "50px" }}></div> */}
        <Grid>
          <div style={{ color: "red", fontWeight: "bold", marginBottom: '10px' }}>{message}</div>
        </Grid>
        <Dialog
          fullScreen={fullScreen}
          onClose={handleCloseToKeep}
          open={openToKeep}
        >
          <DialogTitle>
            <h1
              style={{
                fontFamily: "backToSchool",
                fontSize: "50px",
                color: "#bb0000",
                textAlign: "center",
              }}
            >
              Client à garder
            </h1>
          </DialogTitle>
          <DialogContent>
            <ResearchClient
              showButton={false}
              handleSelectClient={handleSelectClientToKeep}
              today={today}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          fullScreen={fullScreen}
          onClose={handleCloseToRemove}
          open={openToRemove}
        >
          <DialogTitle>
            <h1
              style={{
                fontFamily: "backToSchool",
                color: "#bb0000",
                fontSize: "65px",
                textAlign: "center",
              }}
            >
              Client à supprimer
            </h1>
          </DialogTitle>
          <DialogContent>
            <ResearchClient
              showButton={false}
              handleSelectClient={handleSelectClientToRemove}
              today={today}
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}

export default ClientFusion;
