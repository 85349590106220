/**
 * Get the current datetime with timezone offset 
 * @param 
 * @returns date 
 */
export const localeISODateTime = (date = new Date()) => {
    try {
        let tzoffset = date?.getTimezoneOffset() * 60000; //offset in milliseconds
        return (new Date(date?.getTime() - tzoffset))?.toISOString()?.slice(0,-1);
    } catch(err) {
        return date;
    }
}

// date is Date object, 
// if date string is 2022-07-21T09:35:31.820Z, you need to convert it first to date new Date(date)
export const ISODateTimeWithoutTimezone = (date = new Date()) => {
    return new Date(date.toISOString().slice(0, -1));
}

export const getISODate = (date) => {
    try {
        return date?.split('T')?.[0];
    } catch (err) {
        return date?.toISOString()?.split('T')?.[0];
    }
}

//date is string
export const getISOTime = (date) => {
    try {
        return date?.split('T')?.[1]?.substring(0, 8);
    } catch (err) {
        return date?.toISOString()?.split('T')?.[1]?.substring(0, 8);
    }
}

//date is string
export const DateYmdToDmY = (date, separator = '/') => {
    try {
        return date?.substring(0, 10)?.split('-')?.reverse()?.join(separator)
    } catch (err) {
        return date?.toISOString()?.substring(0, 10)?.split('-')?.reverse()?.join(separator);
    }
}

export const compareDates = (date1, date2) => {
    return Date.parse(date1) >= Date.parse(date2);
}

export const computeDays = (date, nbDays) => {
    var result = new Date(date);
    result.setDate(result.getDate() + parseInt(nbDays));
    return result;
}

export const monthDiffBetweenDates = (dateStart = new Date(), dateEnd = new Date()) => {
    return dateEnd?.getMonth() - dateStart?.getMonth() + (12 * (dateEnd?.getFullYear() - dateStart?.getFullYear()));
}

export const dateIsValid = (date) => {
    return date instanceof Date && !isNaN(date);
}

export const isDateYMD = (dateString) => {
    let regEx = /^\d{4}-\d{2}-\d{2}$/;
    return dateString.match(regEx) != null;
}