import instance from "../../../shared/components/auth/service/AxiosInstance";

export const SiteService = {

    getSites: (pageNo, pageSize) =>
        instance.get(process.env.REACT_APP_API_URL + "site/" +
            '?pageSize=' + pageSize +
            '&pageNo=' + pageNo
        ),

    getSite: (id) => instance.get(process.env.REACT_APP_API_URL + "site/" + id),

    createSite: (site) =>
        instance.post(process.env.REACT_APP_API_URL + 'site/',
            {
                siteId: site.siteId,
                description: site.description,
                address: site.address,
                phone: site.phone,
                fax: site.fax,
                website: site.website,
                email: site.email,
                shower: site.shower,
                restaurant: (site?.restaurant ? site.restaurant : false),
                lockerroom: site.lockerroom,
                laundry: site.laundry,
                hairdresser: (site?.hairdresser ? site.hairdresser : false),
                doctor: (site?.doctor ? site.doctor : false),
                sleepingbag: (site?.sleepingbag ? site.sleepingbag : false),
                deleted: (site?.deleted ? site.deleted : false),
                welcometitle: site.welcometitle
            }
        ),

    updateSite: (id, site) =>
        instance.put(process.env.REACT_APP_API_URL + 'site/' + id,
            {
                siteId: site.siteId,
                description: site.description,
                address: site.address,
                phone: site.phone,
                fax: site.fax,
                website: site.website,
                email: site.email,
                shower: site.shower,
                restaurant: (site?.restaurant ? site.restaurant : false),
                lockerroom: site.lockerroom,
                laundry: site.laundry,
                hairdresser: (site?.hairdresser ? site.hairdresser : false),
                doctor: (site?.doctor ? site.doctor : false),
                sleepingbag: (site?.sleepingbag ? site.sleepingbag : false),
                deleted: (site?.deleted ? site.deleted : false),
                welcometitle: site.welcometitle
            }
        ),

    deleteSite: (id) =>
        instance.delete(process.env.REACT_APP_API_URL + 'site/' + id
        ),

}